import { render, staticRenderFns } from "./TrainingInBioeconomySectors.vue?vue&type=template&id=ed163480&scoped=true&"
import script from "./TrainingInBioeconomySectors.vue?vue&type=script&lang=js&"
export * from "./TrainingInBioeconomySectors.vue?vue&type=script&lang=js&"
import style0 from "./TrainingInBioeconomySectors.vue?vue&type=style&index=0&id=ed163480&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ed163480",
  null
  
)

/* custom blocks */
import block0 from "./TrainingInBioeconomySectors.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
installComponents(component, {VIcon,VImg})
