<template>
  <DefaultLayout>
    <div class="bsat__container">
      <a
        @click="$router.go(-1)"
        class="bsat__btn dark-text px-0 font-weight-bold mb-4"
        style="font-size: 16px !important"
      >
        <v-icon left color="dark">mdi-keyboard-backspace</v-icon>
        {{ $t("back") }}
      </a>
      <div class="bsat__heading font-weight-bold mt-12 mb-6">
        {{ $t("title") }}
      </div>
      <div class="bsat__paragraph">{{ $t("intro.paragraph") }}</div>
      <!-- <i18n path="intro.text" tag="div" class="bsat__paragraph">
        <template #title>
          <strong>{{ $t("intro.title") }}</strong>
        </template>
      </i18n> -->

      <div class="bsat__subtitle">{{ $t("overview.title") }}</div>

      <div>
        <ol>
          <li v-for="(item, i) in webinars" :key="i" class="py-4">
            <router-link :to="item.url" class="bsat__subtitle">{{
              item.title
            }}</router-link>
            <v-img
              contain
              max-width="300px"
              max-height="300px"
              :src="item.icon"
            ></v-img>
          </li>
        </ol>
      </div>
    </div>
  </DefaultLayout>
</template>

<i18n>
{
  "en": {
    "back": "Back",
    "intro": {
      "paragraph": "POWER4BIO organized a series of training webinars about four bio applications: Biochemicals, Food, Bioenergy and Biomaterials. Check the videos and training materials of these webinars."
    },
    "overview": {
      "orderedList": [
        "Training on Biochemicals ",
        "Training on Food",
        "Training on Bioenergy",
        "Training on BioMaterials"
      ],
      "title": "Overview of the training material associated to the of webinar series:"
    },
    "title": "Training in Bioeconomy sectors"
  }
}
</i18n>

<script>
import DefaultLayout from "@/layouts/default";
export default {
  name: "TrainingInBioeconomySector",
  metaInfo: {
    title: "Training in Bioeconomy Sector"
  },
  components: {
    DefaultLayout
  },
  data() {
    return {
      webinars: [
        {
          title: "Training on Biochemicals",
          url: "/webinars/training-on-biochemicals",
          icon: require("@/assets/icons/webinars/biochemicals.jpg")
        },
        {
          title: "Training on Food",
          url: "/webinars/training-on-food-and-feed",
          icon: require("@/assets/icons/webinars/foodAndFeed.jpg")
        },
        {
          title: "Training on Bio-Based Materials",
          url: "/webinars/training-on-biobased-materials",
          icon: require("@/assets/icons/webinars/bioBasedMaterials.jpg")
        },
        {
          title: "Training on Bioenergy ",
          url: "/webinars/training-on-bioenergy",
          icon: require("@/assets/icons/webinars/bioenergy.jpg")
        }
      ]
    };
  },
  computed: {
    orderedList() {
      return Object.values(this.$t("overview.orderedList"));
    }
  }
};
</script>

<style scoped lang="scss">
@import "@/styles/main";
</style>
